<template>
  <div class="lb-top-menu-wrapper" id="topNavigation">
    <div class="lb-top-menu">
      <ul role="list" class="text-center justify-between items-center hidden sm:flex space-x-8">
        <li v-for="(item, index) in topMenu" :key="`ics-101${index}`">
          <NuxtLinkLocale :to="getLocaleString(item.href)" class="flex items-center justify-center text-center flex-wrap">
            <NuxtImg :src="item.custom?.image" height="16" width="16" class="mr-1.5" v-if="item.custom?.image" />
            <span>{{getLocaleString(item.name)}}</span>
          </NuxtLinkLocale>
        </li>
      </ul>

      <div class="hidden lg:flex items-center gap-2 lg:gap-5">
        <div class="flex items-center space-x-2.5 p-2 font-medium cursor-pointer" @click.prevent="openCombobox = true">
          <LucideSearch class="h-5 w-5 sm:h-4 sm:w-4"/>
          <span>{{ $t('navigation.search-placeholder') }}</span>
        </div>
        <LayoutNavigationUserContentArea />
      </div>
    </div>

    <ClientOnly>
      <SearchCombobox />
    </ClientOnly>
  </div>
</template>

<script setup>
const { locale } = useI18n()

const {status} = await useAuth()
const openCombobox = useState("openCombobox", () => false)

const storeConfig = useStoreConfig()
const { isB2BAccount, topMenu } = storeToRefs(storeConfig)

</script>
